import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import R from 'ramda';
import StandardLayout from '../../templates/StandardLayout';

const Page = ({ richText }) => (
  <StandardLayout>
    <div>
      <div className="grid-container">
        <div className="grid-x">
          <div
            className="cell xsmall-16 large-12 large-offset-2"
            dangerouslySetInnerHTML={{ __html: richText }}
          />
        </div>
      </div>
    </div>
  </StandardLayout>
);

Page.propTypes = {
  richText: PropTypes.string,
};

const mapStateToProps = ({ content }) => ({
  richText: R.prop('richText', content),
  link: R.prop('link', content),
});

export default connect(mapStateToProps)(Page);
